import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const ChildSafetyContainer = styled(motion.div)`
  background-color: #000; /* Black background */
  color: #fff; /* White text */
  padding: 0 4em 4em 4em;
  width: 90vw; /* Increased width */
  margin: auto;
  margin-top: 2em;
  z-index: 9;
  @media screen and (max-width: 700px) {
    width: 90vw;
    padding: 0 1em 4em 1em;
  }
  @media screen and (max-width: 500px) {
    width: 94vw;
    padding: 0 1em 4em 1em;
  }
  @media screen and (max-width: 420px) {
    width: 99vw;
    padding: 0 1em 4em 1em;
  }
`;

const ChildSafetyTitle = styled(motion.h1)`
  font-family: Hauora, monospace !important;
  font-size: 2.4rem;
  margin-bottom: 1.5rem;
  text-align: center;
  @media screen and (max-width: 700px) {
    font-size: 2.3rem;
  }
  @media screen and (max-width: 500px) {
    font-size: 2.1rem;
  }
  @media screen and (max-width: 420px) {
    font-size: 1.9rem;
  }
`;

const ChildSafetySubTitle = styled(motion.h2)`
  font-family: Hauora, monospace !important;
  font-size: 1.6rem;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  @media screen and (max-width: 700px) {
    font-size: 1.3rem;
  }
  @media screen and (max-width: 420px) {
    font-size: 1.1rem;
  }
`;

const ChildSafetyParagraph = styled(motion.p)`
  font-family: 'Open Sans', sans-serif !important;
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
  text-align: left;
  @media screen and (max-width: 700px) {
    font-size: 1.2rem;
  }
  @media screen and (max-width: 500px) {
    font-size: 1.1rem;
  }
  @media screen and (max-width: 420px) {
    font-size: 1rem;
  }
`;

const ChildSafetyCard = () => {
  return (
    <ChildSafetyContainer>
      <ChildSafetyTitle
        initial={{ y: -50 }}
        animate={{ y: 0 }}
        transition={{ type: 'spring', stiffness: 120 }}
      >
        Child Safety Policy
      </ChildSafetyTitle>
      <ChildSafetyParagraph
        initial={{ y: -50 }}
        animate={{ y: 0 }}
        transition={{ type: 'spring', stiffness: 120, delay: 0.1 }}
      >
        <strong>App Name:</strong> Dumpit
      </ChildSafetyParagraph>
      <ChildSafetyParagraph
        initial={{ y: -50 }}
        animate={{ y: 0 }}
        transition={{ type: 'spring', stiffness: 120, delay: 0.15 }}
      >
        <strong>Developer:</strong> Syneidisi Tech Private Limited
      </ChildSafetyParagraph>
      <ChildSafetyParagraph
        initial={{ y: -50 }}
        animate={{ y: 0 }}
        transition={{ type: 'spring', stiffness: 120, delay: 0.2 }}
      >
        <strong>Date:</strong> 22nd February 2022
      </ChildSafetyParagraph>
      <ChildSafetyParagraph
        initial={{ y: -50 }}
        animate={{ y: 0 }}
        transition={{ type: 'spring', stiffness: 120, delay: 0.25 }}
      >
        At Dumpit, we prioritize the safety and well-being of children using our mobile application, Dumpit. This declaration outlines our commitment to ensuring compliance with child safety standards and applicable regulations.
      </ChildSafetyParagraph>

      <ChildSafetySubTitle
        initial={{ y: -50 }}
        animate={{ y: 0 }}
        transition={{ type: 'spring', stiffness: 120, delay: 0.3 }}
      >
        1. Compliance with Regulations
      </ChildSafetySubTitle>
      <ChildSafetyParagraph
        initial={{ y: -50 }}
        animate={{ y: 0 }}
        transition={{ type: 'spring', stiffness: 120, delay: 0.35 }}
      >
        - The Children’s Online Privacy Protection Act (COPPA)
      </ChildSafetyParagraph>
      <ChildSafetyParagraph
        initial={{ y: -50 }}
        animate={{ y: 0 }}
        transition={{ type: 'spring', stiffness: 120, delay: 0.4 }}
      >
        - The General Data Protection Regulation (GDPR) – Children's Data Protection
      </ChildSafetyParagraph>
      <ChildSafetyParagraph
        initial={{ y: -50 }}
        animate={{ y: 0 }}
        transition={{ type: 'spring', stiffness: 120, delay: 0.45 }}
      >
        - App Store and Google Play child safety policies
      </ChildSafetyParagraph>

      <ChildSafetySubTitle
        initial={{ y: -50 }}
        animate={{ y: 0 }}
        transition={{ type: 'spring', stiffness: 120, delay: 0.5 }}
      >
        2. Age-Appropriate Content and Features
      </ChildSafetySubTitle>
      <ChildSafetyParagraph
        initial={{ y: -50 }}
        animate={{ y: 0 }}
        transition={{ type: 'spring', stiffness: 120, delay: 0.55 }}
      >
        Dumpit is designed to provide age-appropriate content suitable for the intended audience.
      </ChildSafetyParagraph>
      <ChildSafetyParagraph
        initial={{ y: -50 }}
        animate={{ y: 0 }}
        transition={{ type: 'spring', stiffness: 120, delay: 0.6 }}
      >
        We do not include harmful, explicit, or inappropriate content.
      </ChildSafetyParagraph>
      <ChildSafetyParagraph
        initial={{ y: -50 }}
        animate={{ y: 0 }}
        transition={{ type: 'spring', stiffness: 120, delay: 0.65 }}
      >
        Parental controls and settings are available where necessary.
      </ChildSafetyParagraph>

      <ChildSafetySubTitle
        initial={{ y: -50 }}
        animate={{ y: 0 }}
        transition={{ type: 'spring', stiffness: 120, delay: 0.7 }}
      >
        3. Data Collection and Privacy
      </ChildSafetySubTitle>
      <ChildSafetyParagraph
        initial={{ y: -50 }}
        animate={{ y: 0 }}
        transition={{ type: 'spring', stiffness: 120, delay: 0.75 }}
      >
        - We collect only the minimum personal information necessary for app functionality.
      </ChildSafetyParagraph>
      <ChildSafetyParagraph
        initial={{ y: -50 }}
        animate={{ y: 0 }}
        transition={{ type: 'spring', stiffness: 120, delay: 0.8 }}
      >
        - No personal data is collected from children under the age of 13 (or the applicable age of digital consent) without verified parental consent.
      </ChildSafetyParagraph>
      <ChildSafetyParagraph
        initial={{ y: -50 }}
        animate={{ y: 0 }}
        transition={{ type: 'spring', stiffness: 120, delay: 0.85 }}
      >
        - We implement strict security measures to protect user data from unauthorized access.
      </ChildSafetyParagraph>

      <ChildSafetySubTitle
        initial={{ y: -50 }}
        animate={{ y: 0 }}
        transition={{ type: 'spring', stiffness: 120, delay: 0.9 }}
      >
        4. Safety Features and Moderation
      </ChildSafetySubTitle>
      <ChildSafetyParagraph
        initial={{ y: -50 }}
        animate={{ y: 0 }}
        transition={{ type: 'spring', stiffness: 120, delay: 0.95 }}
      >
        - The app incorporates community guidelines to promote a safe and respectful environment.
      </ChildSafetyParagraph>
      <ChildSafetyParagraph
        initial={{ y: -50 }}
        animate={{ y: 0 }}
        transition={{ type: 'spring', stiffness: 120, delay: 1 }}
      >
        - We employ content moderation to prevent inappropriate or harmful interactions.
      </ChildSafetyParagraph>
      <ChildSafetyParagraph
        initial={{ y: -50 }}
        animate={{ y: 0 }}
        transition={{ type: 'spring', stiffness: 120, delay: 1.05 }}
      >
        - Reporting and blocking mechanisms are available for users to flag any safety concerns.
      </ChildSafetyParagraph>

      <ChildSafetySubTitle
        initial={{ y: -50 }}
        animate={{ y: 0 }}
        transition={{ type: 'spring', stiffness: 120, delay: 1.1 }}
      >
        5. Parental Controls and Guidance
      </ChildSafetySubTitle>
      <ChildSafetyParagraph
        initial={{ y: -50 }}
        animate={{ y: 0 }}
        transition={{ type: 'spring', stiffness: 120, delay: 1.15 }}
      >
        Parents and guardians are encouraged to review and supervise their child’s use of Dumpit.
      </ChildSafetyParagraph>
      <ChildSafetyParagraph
        initial={{ y: -50 }}
        animate={{ y: 0 }}
        transition={{ type: 'spring', stiffness: 120, delay: 1.2 }}
      >
        We provide clear privacy policies and parental guidance within the app settings.
      </ChildSafetyParagraph>

      <ChildSafetySubTitle
        initial={{ y: -50 }}
        animate={{ y: 0 }}
        transition={{ type: 'spring', stiffness: 120, delay: 1.25 }}
      >
        6. Regular Review and Updates
      </ChildSafetySubTitle>
      <ChildSafetyParagraph
        initial={{ y: -50 }}
        animate={{ y: 0 }}
        transition={{ type: 'spring', stiffness: 120, delay: 1.3 }}
      >
        - Our team routinely reviews and updates safety features in response to emerging risks.
      </ChildSafetyParagraph>
      <ChildSafetyParagraph
        initial={{ y: -50 }}
        animate={{ y: 0 }}
        transition={{ type: 'spring', stiffness: 120, delay: 1.35 }}
      >
        - We stay up to date with the latest regulatory changes to maintain compliance.
      </ChildSafetyParagraph>

      <ChildSafetyParagraph
        initial={{ y: -50 }}
        animate={{ y: 0 }}
        transition={{ type: 'spring', stiffness: 120, delay: 1.4 }}
      >
        By implementing these measures, we are committed to providing a safe and positive experience for all users, particularly children.
      </ChildSafetyParagraph>
      <ChildSafetyParagraph
        initial={{ y: -50 }}
        animate={{ y: 0 }}
        transition={{ type: 'spring', stiffness: 120, delay: 1.45 }}
      >
        For further inquiries or concerns regarding child safety in Dumpit, please contact us at:
      </ChildSafetyParagraph>
      <ChildSafetyParagraph
        initial={{ y: -50 }}
        animate={{ y: 0 }}
        transition={{ type: 'spring', stiffness: 120, delay: 1.5 }}
      >
        <strong>Email:</strong> contact@tagssocial.in
      </ChildSafetyParagraph>
      <ChildSafetyParagraph
        initial={{ y: -50 }}
        animate={{ y: 0 }}
        transition={{ type: 'spring', stiffness: 120, delay: 1.55 }}
      >
        <strong>Address:</strong> GTPS Engineers (B) Housing Society, Plot 129, Sector 5, Ulwe, Navi Mumbai, 410206
      </ChildSafetyParagraph>
    </ChildSafetyContainer>
  );
};

export default ChildSafetyCard;
